import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
// import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints } from '../../hooks'
import CakePrice from '../../components/CakePrice/CakePrice'
import Logo from './components/Logo'
import {
  MENU_HEIGHT,
  //  MOBILE_MENU_HEIGHT,
  TOP_BANNER_HEIGHT,
  TOP_BANNER_HEIGHT_MOBILE,
  FOOTER_HEIGHT,
  CONTAINER,
} from './config'
import { NavProps } from './types'
// import LangSelector from '../../components/LangSelector/LangSelector'
import { MenuContext } from './context'
import { Button } from '../../components/Button'
import { MenuMobile } from '../../components/Svg'
import Footer from '../../components/Footer'

const GROUND_HEIGHT = 95

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;

  overflow-x: hidden;
  flex-direction: column;
  background: linear-gradient(180deg, #ffffff 0%, #fef4f4 9.97%, #fcc8c8 99.98%);

  &:before {
    content: '';
    z-index: 0;
    position: absolute;

    width: 253.19px;
    aspect-ratio: calc(253.19 / 275.12);

    background: url('/images/ball-bg-1.svg') no-repeat;
    background-size: contain;
  }

  &:after {
    content: '';
    z-index: 0;
    position: absolute;

    right: -25px;
    top: 75px;

    width: 301.41px;
    aspect-ratio: calc(301.41 / 327.5);

    background: url('/images/ball-bg-2.svg') no-repeat;
    background-size: contain;
  }
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  transform: translate3d(0, 0, 0);
  padding: 16px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 16px 0;
  }
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: relative;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  width: 100%;
  z-index: 20;
`

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  margin: 0 auto;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;

  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  background: transparent;
`
const StyledFlex = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 16px;
  transform: translate(0, 100%);
  width: 145px;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 24px;
  }
`

const MenuDesktopPosition = styled(Flex).attrs({ alignItems: 'center', justifyContent: 'center' })`
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const SocialsMobilePosition = styled.div`
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translate(-50%, 50%);
`

const Menu: React.FC<NavProps> = ({
  linkComponent = 'a',
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
}) => {
  const { isMobile, isMd } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  // const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  // const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  const [isBottomNavOpen, setIsBottomNavOpen] = useState(false)
  const bottomNavRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bottomNavRef.current && !bottomNavRef.current.contains(event.target as Node)) {
        setIsBottomNavOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [bottomNavRef])

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <Wrapper>
        <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
          {/* {banner && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>} */}
          <Box maxWidth={CONTAINER} mx="auto">
            <StyledNav justifyContent="space-between">
              {!isMobile ? (
                <>
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    height="100%"
                    alignItems="center"
                    position="relative"
                  >
                    <Flex alignItems="center">
                      <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                    </Flex>

                    <MenuDesktopPosition>
                      <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                    </MenuDesktopPosition>

                    <Flex alignItems="center">
                      {globalMenu} {userMenu}
                    </Flex>
                  </Flex>

                  {/* {!isMobile && !isMd && (
                    <Flex alignItems="center" height="100%">
                      <Box mr="12px">
                        <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                      </Box>
                    </Flex>
                  )} */}
                </>
              ) : (
                <>
                  <Box mr="8px">
                    <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                  </Box>

                  <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="flex-end"
                    position="relative"
                    ref={bottomNavRef}
                    padding="16px"
                  >
                    <Flex alignItems="center" height="100%">
                      {!isMobile && !isMd && (
                        <Box mr="12px">
                          <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                        </Box>
                      )}
                      {globalMenu} {userMenu}
                      <Box>
                        <Button
                          onClick={() => setIsBottomNavOpen(!isBottomNavOpen)}
                          padding="0"
                          ml="12px"
                          variant="no-bg"
                        >
                          <MenuMobile color="black" />
                        </Button>

                        {isBottomNavOpen && (
                          <StyledFlex>
                            <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                          </StyledFlex>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                </>
              )}
            </StyledNav>
          </Box>
        </FixedContainer>
        {subLinks && (
          <Flex justifyContent="space-around">
            {/* {isMobile && (
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight - 90}px`}
                activeItem={activeSubItem}
              />
            )} */}
          </Flex>
        )}
        <BodyWrapper>
          <Inner isPushed={false} showMenu={showMenu}>
            {children}
          </Inner>
        </BodyWrapper>
        <Box mt="auto" height={FOOTER_HEIGHT} background="#282828">
          <Footer />
        </Box>
      </Wrapper>
    </MenuContext.Provider>
  )
}

export default Menu
