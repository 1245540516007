import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17">
      <path d="M12.5475 2.80424L16.6248 7.46406C16.8639 7.73728 17 8.11295 17 8.5C17 8.88705 16.8639 9.26272 16.6248 9.53594L12.5475 14.1958C12.335 14.4386 12.0494 14.5714 11.7506 14.5714C11.1297 14.5714 10.625 13.9946 10.625 13.285V10.9286H6.375C5.7873 10.9286 5.3125 10.3859 5.3125 9.71429V7.28571C5.3125 6.61406 5.7873 6.07143 6.375 6.07143H10.625V3.71496C10.625 3.00536 11.1297 2.42857 11.7506 2.42857C12.0494 2.42857 12.335 2.56518 12.5475 2.80424ZM5.3125 2.42857H3.1875C2.5998 2.42857 2.125 2.97121 2.125 3.64286V13.3571C2.125 14.0288 2.5998 14.5714 3.1875 14.5714H5.3125C5.9002 14.5714 6.375 15.1141 6.375 15.7857C6.375 16.4574 5.9002 17 5.3125 17H3.1875C1.42773 17 0 15.3683 0 13.3571V3.64286C0 1.6317 1.42773 0 3.1875 0H5.3125C5.9002 0 6.375 0.542634 6.375 1.21429C6.375 1.88594 5.9002 2.42857 5.3125 2.42857Z" />
    </Svg>
  )
}

export default Icon
