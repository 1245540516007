/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { baseColors, darkColors, lightColors } from '../../theme/colors'
import { Flex } from '../Box'
import { Link } from '../Link'
import { StyledFooter } from './styles'
// import { FooterProps } from './types'
// import { ThemeSwitcher } from '../ThemeSwitcher'
// import LangSelector from '../LangSelector/LangSelector'
// import CakePrice from '../CakePrice/CakePrice'
// import { LogoWithTextIcon, ArrowForwardIcon } from '../Svg'
// import { Button } from '../Button'
// import { Colors } from '../..'
import { FooterProps } from './types'
import { TelegramIcon, TwitterIcon, DocumentIcon } from '../Svg'
import { Text } from '../Text'

const MenuItem: React.FC<FooterProps> = ({ ...props }) => {
  return (
    <StyledFooter {...props}>
      <Flex flexDirection="column" width="33%">
        <Flex alignItems="center" mb="10px">
          <img src="/images/footer-logo.svg" alt="footer-logo" />

          <Text fontSize={25} color="white" ml="8px">
            Gizzard
          </Text>
        </Flex>
        <Text color="white" fontSize={16}>
          The Native DEX on Plume Network
        </Text>
        <Text color="white" fontSize={16}>
          © 2024 Gizzard Finance
        </Text>
      </Flex>

      <Flex style={{ gap: '12px' }} mr={['0', , , , '8px']} justifyContent="center" alignItems="center" width="33%">
        <Flex
          alignItems="center"
          justifyContent="center"
          background="rgba(255, 255, 255, 0.3)"
          borderRadius={8}
          width={46}
          height={46}
        >
          <Link external href="https://x.com/Gizzard_Defi">
            <TwitterIcon width="26px" color="rgba(255, 255, 255, 0.68)" />
          </Link>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          background="rgba(255, 255, 255, 0.3)"
          borderRadius={8}
          width={46}
          height={46}
        >
          <Link external href="https://t.me/gizzard_group">
            <TelegramIcon width="30px" color="rgba(255, 255, 255, 0.68)" />
          </Link>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          background="rgba(255, 255, 255, 0.3)"
          borderRadius={8}
          width={46}
          height={46}
        >
          <Link external href="https://docs.gizzard.finance">
            <DocumentIcon width="22px" color="transparent" />
          </Link>
        </Flex>
      </Flex>

      <Flex style={{ gap: '26px' }} width="33%" justifyContent="flex-end">
        <Link external href="#">
          <Text color="white" fontSize={20}>
            Brand Kit
          </Text>
        </Link>
        <Link external href="mailto:hi@gizzard.finance">
          <Text color="white" fontSize={20}>
            Contact Us
          </Text>
        </Link>
      </Flex>
    </StyledFooter>
  )
}

export default MenuItem
