import styled from 'styled-components'
import { PolymorphicComponent } from '../../util/polymorphic'
import Button from './Button'
import { BaseButtonProps } from './types'

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps>`
  padding: 0;
  width: ${({ scale }) => (scale === 'sm' ? '26px' : '36px')};
  height: ${({ scale }) => (scale === 'sm' ? '26px' : '36px')};
  aspect-ratio: 1/1;
  /* border: 2px solid black;
  border-radius: 50%; */

  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.md} {
    width: ${({ scale }) => (scale === 'sm' ? '32px' : '48px')};
    height: ${({ scale }) => (scale === 'sm' ? '32px' : '48px')};
  }
`

export default IconButton
