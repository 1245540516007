import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { ArrowBackIcon, CloseIcon, ChevronLeftIcon } from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { ModalProps } from './types'
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: transparent;
  display: flex;
  gap: 12px;

  ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 12px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 12px 24px;
  }
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;

  /* ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 10px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 24px;
  } */
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  const { isMobile } = useMatchBreakpoints()

  return (
    <IconButton scale="sm" variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="black" width={isMobile ? '16px' : '24px'} />
    </IconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ChevronLeftIcon color="black" width="36px" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)<{ minWidth: string; maxWidth: string }>`
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 50px 0px #f43b3a;
  border-radius: 30px;
  width: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth};
  }
`

export const Dots = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const Dot = styled.span`
  width: 16px;
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 50%;
  border: 2px solid black;

  &:first-child {
    background-color: yellow;
  }

  &:nth-child(2) {
    background: green;
  }

  &:nth-child(3) {
    background: #3b9aff;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 26px;
  }
`

export const HiddenPosition = styled.div`
  visibility: hidden;
`
