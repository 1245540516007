import { useTranslation } from 'contexts/Localization'
import { Button, Text, Flex, WarningIcon } from 'packages/uikit'
import { setupNetwork } from 'utils/wallet'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { connector, library } = useWeb3React()

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork(library)
    onDismiss?.()
    document.location.reload()
  }

  return (
    <>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text mb="16px" textAlign="center" fontSize="25px">
          {t('Wrong Network')}
        </Text>
      </Flex>
      {/* {connector instanceof InjectedConnector && ( */}
      <Button variant="primary" onClick={handleSwitchNetwork} width="100%" mb="16px" style={{ whiteSpace: 'nowrap' }}>
        <Flex alignItems="center" justifyContent="center" width="100%">
          Switch Network
        </Flex>
      </Button>

      <Text mb="16px" textAlign="center" fontSize="16px">
        {t('Please switch to Plume Network to continue.')}
      </Text>
    </>
  )
}

export default WalletWrongNetwork
