/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  // createElement,
  memo,
} from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
// import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'
import { Text } from '../Text'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'

// const StyledIconWrapper = styled.div<{ isActive?: boolean }>`
//   display: flex;
//   align-items: center;
//   margin-right: 8px;
// `

// const SoonLabel = styled(Text)`
//   position: absolute;
//   right: 0;
//   top: 0;
//   transform: translate(calc(100% + 5px), -50%);
// `

const StyledFlex = styled(Flex)`
  border-radius: 20px;
  gap: 26px;
  align-items: center;
`

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <StyledFlex {...props}>
      {items.map(({ label, items: menuItems = [], href, icon, fillIcon, commingSoon }, i) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          <>
            <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor} isComming={commingSoon} key={i}>
              {/* {icon && (
                  <StyledIconWrapper isActive={isActive}>
                    <AnimatedIconComponent icon={icon} fillIcon={fillIcon} width="25px" height="25px" isActive={isActive} />
                  </StyledIconWrapper>
                )} */}
              <Text color={isActive ? 'rgba(244, 59, 58, 1)' : 'black'} fontSize="30px">
                {label}
              </Text>
            </MenuItem>
          </>
        )
      })}
    </StyledFlex>
  )
}

export default memo(MenuItems)
