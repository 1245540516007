import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12">
      <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" />
    </Svg>
  )
}

export default Icon
