import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 22">
      <path d="M9.05764 0H14.4617C15.0148 0 15.5461 0.218616 15.938 0.60441L18.8948 3.51501C19.2867 3.9008 19.5088 4.42377 19.5088 4.96816V14.403C19.5088 15.5389 18.5725 16.4605 17.4185 16.4605H9.05764C7.90367 16.4605 6.96742 15.5389 6.96742 14.403V2.05757C6.96742 0.921618 7.90367 0 9.05764 0ZM2.09023 5.48684H5.57394V8.23026H2.78697V19.2039H11.1479V17.8322H13.9348V19.8898C13.9348 21.0258 12.9986 21.9474 11.8446 21.9474H2.09023C0.936247 21.9474 0 21.0258 0 19.8898V7.54441C0 6.40846 0.936247 5.48684 2.09023 5.48684Z" />
    </Svg>
  )
}

export default Icon
