import React from 'react'
import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import MoreHorizontal from '../../components/Svg/Icons/MoreHorizontal'
import { ButtonProps } from '../../components/Button'
import { connectorLocalStorageKey, walletConnectConfig, walletLocalStorageKey } from './config'
import { Login, Config } from './types'
import { Flex } from '../../components/Box'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletButton = styled(Button).attrs({ width: '100%', variant: 'secondary', py: '16px' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

// const Recent = styled.div`
//   border: 1px solid black;
//   padding: 5px;
//   border-radius: 8px;
//   background: transparent;
// `

interface MoreWalletCardProps extends ButtonProps {
  t: (key: string) => string
}

export const MoreWalletCard: React.FC<MoreWalletCardProps> = ({ t, ...props }) => {
  return (
    <WalletButton variant="tertiary" {...props}>
      <MoreHorizontal width="40px" mb="8px" color="textSubtle" />
      <Text fontSize="14px">{t('More')}</Text>
    </WalletButton>
  )
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig
  const { t } = useTranslation()

  return (
    <WalletButton
      variant="tertiary"
      onClick={() => {
        // TW point to WC on desktop
        if (title === 'Trust Wallet' && walletConnectConfig && isDesktop) {
          login(walletConnectConfig.connectorId)
          localStorage?.setItem(walletLocalStorageKey, walletConnectConfig.title)
          localStorage?.setItem(connectorLocalStorageKey, walletConnectConfig.connectorId)
          onDismiss()
          return
        }
        if (!window.ethereum && walletConfig.href) {
          window.open(walletConfig.href, '_blank', 'noopener noreferrer')
        } else {
          login(walletConfig.connectorId)
          localStorage?.setItem(walletLocalStorageKey, walletConfig.title)
          localStorage?.setItem(connectorLocalStorageKey, walletConfig.connectorId)
          onDismiss()
        }
      }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Flex alignItems="center" style={{ gap: '8px' }}>
        <Icon width="25px" />
        {title}
      </Flex>
    </WalletButton>
  )
}

export default WalletCard
