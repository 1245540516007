import React, { useContext } from 'react'
import { MenuContext } from '../../widgets/Menu/context'
import { Flex } from '../Box'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'
import { StyledBottomNavItem, StyledBottomNavText, ActiveIconWrapper } from './styles'
import { BottomNavItemProps } from './types'
import { ChevronUpIcon } from '../Svg'

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  commingSoon,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext)
  const bottomNavItemContent = (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      width="100%"
      position="relative"
      background="#d9d9d9"
    >
      {/* {icon && <AnimatedIconComponent icon={icon} fillIcon={fillIcon} height="22px" width="21px" isActive={isActive} />} */}
      {isActive ? (
        <ActiveIconWrapper>
          <ChevronUpIcon width="36px" color="primary" />
        </ActiveIconWrapper>
      ) : null}
      <StyledBottomNavText color="black" fontWeight={isActive ? '600' : '400'} textAlign="center" fontSize="10px">
        {label}
      </StyledBottomNavText>
    </Flex>
  )

  return showItemsOnMobile ? (
    <StyledBottomNavItem commingSoon={commingSoon} type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem commingSoon={commingSoon} as={linkComponent} href={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  )
}

export default BottomNavItem
