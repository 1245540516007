import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 26">
      <path
        d="M7.15385 23.1538L1 25L2.53846 19.4615V2.84615C2.53846 2.35652 2.70055 1.88695 2.98906 1.54073C3.27758 1.19451 3.66889 1 4.07692 1H19.4615C19.8695 1 20.2609 1.19451 20.5494 1.54073C20.8378 1.88695 21 2.35652 21 2.84615V21.3077C21 21.7973 20.8378 22.267 20.5494 22.6131C20.2609 22.9593 19.8695 23.1538 19.4615 23.1538H7.15385Z"
        stroke="white"
        strokeOpacity="0.68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15625 9.30664H16.387"
        stroke="white"
        strokeOpacity="0.68"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15625 14.8457H13.3101"
        stroke="white"
        strokeOpacity="0.68"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
