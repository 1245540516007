import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
      <path
        d="M20.3846 1H2.61538C1.72323 1 1 1.72323 1 2.61538V20.3846C1 21.2768 1.72323 22 2.61538 22H20.3846C21.2768 22 22 21.2768 22 20.3846V2.61538C22 1.72323 21.2768 1 20.3846 1Z"
        fill="#F43B3A"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 5.8457H22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.5 18C13.9853 18 16 15.9854 16 13.5C16 11.0147 13.9853 9 11.5 9C9.01473 9 7 11.0147 7 13.5C7 15.9854 9.01473 18 11.5 18Z"
        stroke="black"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6817 16.6856L8.3125 10.3164"
        stroke="black"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
