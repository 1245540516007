import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
      <path
        d="M20.3846 1H2.61538C1.72323 1 1 1.72323 1 2.61538V20.3846C1 21.2768 1.72323 22 2.61538 22H20.3846C21.2768 22 22 21.2768 22 20.3846V2.61538C22 1.72323 21.2768 1 20.3846 1Z"
        fill="#4B973F"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 5.8457H22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.65625 14.7318L9.88702 17.1549L15.5409 10.6934"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
