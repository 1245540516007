import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5886 16.9803C12.3475 17.2214 12.0205 17.3569 11.6795 17.3569H6.31604C5.97505 17.3569 5.64802 17.2214 5.40691 16.9803L1.0172 12.5906C0.776084 12.3495 0.640625 12.0224 0.640625 11.6815V6.31799C0.640625 5.97701 0.776084 5.64997 1.0172 5.40886L5.40691 1.01915C5.64802 0.778037 5.97505 0.642578 6.31604 0.642578H11.6795C12.0205 0.642578 12.3475 0.778037 12.5886 1.01915L16.9783 5.40886C17.2194 5.64997 17.3549 5.97701 17.3549 6.31799V11.6815C17.3549 12.0224 17.2194 12.3495 16.9783 12.5906L12.5886 16.9803Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 5.14258V9.32115" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.00112 12.8567C8.8236 12.8567 8.67969 12.7128 8.67969 12.5353C8.67969 12.3578 8.8236 12.2139 9.00112 12.2139"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.8567C9.17752 12.8567 9.32143 12.7128 9.32143 12.5353C9.32143 12.3578 9.17752 12.2139 9 12.2139"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
