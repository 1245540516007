import { useState } from 'react'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  Box,
  Flex,
  // LinkExternal,
  Text,
  LogoutIcon,
  Button,
  useMatchBreakpoints,
  MetamaskIcon,
} from 'packages/uikit'
import { parseUnits } from '@ethersproject/units'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { FetchStatus } from 'config/constants/types'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
// import { FlexGap } from 'components/Layout/Flex'
// import { getBscScanLink } from 'utils'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'
import { CopyButton } from '../../CopyButton'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const ModalHeader = styled(UIKitModalHeader)``

const Tabs = styled.div`
  padding: 16px 24px;
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const hasLowBnbBalance = fetchStatus === FetchStatus.Fetched && balance.lte(LOW_BNB_BALANCE)
  const { account } = useWeb3React()
  const accountEllipsis = account ? `${account.substring(0, 13)}...${account.substring(account.length - 10)}` : null
  const { logout } = useAuth()
  const { isMobile } = useMatchBreakpoints()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  const handleClick = (index: number) => {
    setView(index)
  }

  const handleOpenTransactionModal = () => {
    setView(WalletView.TRANSACTIONS)
  }

  const handleOpenInfoModal = () => {
    setView(WalletView.WALLET_INFO)
  }

  const TabsComponent: React.FC = () => (
    <Tabs>
      <ButtonMenu scale="sm" variant="cancel" onItemClick={handleClick} activeIndex={view} fullWidth>
        <ButtonMenuItem>{t('Wallet')}</ButtonMenuItem>
        <ButtonMenuItem>{t('Transactions')}</ButtonMenuItem>
      </ButtonMenu>
    </Tabs>
  )

  return (
    <ModalContainer title={t('Welcome!')} minWidth="320px" maxWidth="420px">
      <ModalHeader>
        <ModalTitle>
          <Heading color="black">{t('')}</Heading>
        </ModalTitle>
        <IconButton scale="sm" variant="text" onClick={onDismiss}>
          <CloseIcon width={isMobile ? '16px' : '24px'} color="black" />
        </IconButton>
      </ModalHeader>
      {/* {view !== WalletView.WRONG_NETWORK && <TabsComponent />} */}

      <ModalBody p={view === WalletView.TRANSACTIONS ? '0px' : isMobile ? '0 12px 12px' : '0 24px 24px'} width="100%">
        {view === WalletView.WRONG_NETWORK ? null : (
          <>
            <Flex justifyContent="center" alignItems="center" mb="8px">
              <Flex alignItems="center" flexDirection="column">
                <Flex
                  justifyContent="center"
                  display={['none', , , , 'block']}
                  width={53}
                  height={53}
                  borderRadius="50%"
                  background="black"
                >
                  <MetamaskIcon width={32} />
                </Flex>
                <Flex alignItems="center">
                  <Text color="black" fontSize={['16px', , , , '20px']} mr="8px">
                    {accountEllipsis}{' '}
                  </Text>
                  <CopyButton width={16} text={account} tooltipMessage={t('')} tooltipTop={-40} />
                </Flex>
              </Flex>

              {/* <FlexGap alignItems="center" gap="8px">
                <Box mt="1px">
                  <LinkExternal
                    href={getBscScanLink(account, 'address')}
                    margin={false}
                    iconWidth={isMobile ? '14px' : '20px'}
                  />
                </Box>
              </FlexGap> */}
            </Flex>

            {view === WalletView.WALLET_INFO && (
              <WalletInfo
                hasLowBnbBalance={hasLowBnbBalance}
                onDismiss={onDismiss}
                onClick={handleOpenTransactionModal}
                account={account}
              />
            )}

            <Box mb="16px">
              <WalletTransactions onBack={handleOpenInfoModal} />
            </Box>

            <Button variant="no-bg" onClick={handleLogout} width="fit-content" height="100%" mx="auto">
              <LogoutIcon width="22px" mr="8px" />
              <Text>Disconnect</Text>
            </Button>
          </>
        )}

        {/* {view === WalletView.WALLET_INFO && (
          <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} onClick={handleOpenTransactionModal} />
        )}
        {view === WalletView.TRANSACTIONS && <WalletTransactions onBack={handleOpenInfoModal} />} */}
        {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
      </ModalBody>
    </ModalContainer>
  )
}

export default WalletModal
