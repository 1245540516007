import { fontWeight } from 'styled-system'
import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '52px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: 'primary',
    borderRadius: '10px',
    border: '2px solid black',
    color: '#282828',
    boxShadow: 'none',
    fontWeight: '400',
    fontSize: ['16px', , , , '20px'],
  },
  [variants.SECONDARY]: {
    backgroundColor: '#D9D9D9',
    border: '2px solid',
    borderColor: 'text',
    boxShadow: 'none',
    fontWeight: '400',
    fontSize: ['16px', , , , '20px'],
    borderRadius: '10px',
  },
  [variants.TERTIARY]: {
    backgroundColor: 'tertiary',
    boxShadow: 'none',
    color: 'primary',
  },
  [variants.SUBTLE]: {
    backgroundColor: '#222426',
    color: 'backgroundAlt',
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'primary',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: 'textSubtle',
    boxShadow: 'none',
  },
  [variants.BLASTBTN]: {
    backgroundColor: '#4B973F',
    borderRadius: '8px',
    color: '#282828',
    boxShadow: 'none',
  },
  [variants.BLASTDISABLED]: {
    backgroundColor: '#AFAFAF',
    color: '#282828',
    boxShadow: 'none',
  },
  [variants.PINK]: {
    backgroundColor: 'primary',
    color: 'black',
    boxShadow: 'none',
  },
  [variants.SLIPP]: {
    backgroundColor: 'primary',
    borderRadius: '8px',
    border: '2px solid black',
    color: '#282828',
    boxShadow: 'none',
  },
  [variants.INACTIVE]: {
    backgroundColor: 'transparent',
    color: 'pink',
    boxShadow: 'none',
  },
  [variants.CANCEL]: {
    backgroundColor: '#D9D9D9',
    color: 'black',
    borderRadius: '8px',
    boxShadow: 'none',
  },
  [variants.BUTTON]: {
    backgroundColor: '#8B8B8B',
    color: 'black',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  [variants.TAB]: {
    backgroundColor: 'transaparent',
    borderRadius: '4px',
    border: '1px solid black',
  },
  [variants.TAB_ACTIVE]: {
    backgroundColor: 'primary',
    borderRadius: '4px',
    border: '1px solid black',
  },
  [variants.SQUARE]: {
    backgroundColor: 'primary',

    borderRadius: '8px',
    color: '#282828',
    boxShadow: 'none',
  },
  [variants.ICON]: {
    backgroundColor: 'transparent',
    padding: '0',
    height: '0',
    boxShadow: 'none',
  },
  [variants.STAKED_BUTTON]: {
    backgroundColor: 'primary',
    borderRadius: '8px',
    color: '#282828',
    boxShadow: 'none',
    fontSize: ['12px', , , , '14px'],
  },
  [variants.NO_BG]: {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    color: '#282828',
    boxShadow: 'none',
    fontWeight: '400',
    fontSize: ['16px', , , , '20px'],
    ':disabled': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  [variants.FARM_ADD_BUTTON]: {
    backgroundColor: 'primary',
    borderRadius: '10px',
    border: 'none',
    color: '#282828',
    boxShadow: '0px 4px 4px 0px #00000040',
    fontSize: ['16px', , , , '20px'],
    fontWeight: '400',
    ':disabled': {
      border: 'none',
    },
  },
  [variants.FARM_REMOVE_BUTTON]: {
    backgroundColor: '#BEBEBE',
    borderRadius: '10px',
    border: 'none',
    color: '#282828',
    boxShadow: '0px 4px 4px 0px #00000040',
    fontSize: ['16px', , , , '20px'],
    fontWeight: '400',
    ':disabled': {
      border: 'none',
    },
  },
}
