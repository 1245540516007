import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Text, ChevronLeftIcon, TrashIcon } from 'packages/uikit'
import { AppDispatch } from 'state'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { clearAllTransactions } from 'state/transactions/actions'
import orderBy from 'lodash/orderBy'
import TransactionRow from './TransactionRow'

const WalletTransactions: React.FC<{ onBack?: () => void }> = ({ onBack }) => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const allTransactions = useAllTransactions()
  const sortedTransactions = orderBy(Object.values(allTransactions).filter(isTransactionRecent), 'addedTime', 'desc')

  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" padding={['12px 0']}>
        <Flex justifyContent="space-between" width="100%">
          {/* <Button variant="icon" onClick={onBack} height="24px">
            <Flex justifyContent="center" border="2px solid black" width="24px" height="24px" borderRadius="50%">
              <ChevronLeftIcon color="black" />
            </Flex>
          </Button> */}

          <Flex>
            <Text color="black">{t('Recent Transactions:')}</Text>
          </Flex>

          {sortedTransactions.length > 0 && (
            <Button scale="sm" onClick={handleClearAll} px="0" variant="no-bg">
              {t('Clear')} <TrashIcon color="transparent" width={16} ml="6px" />
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        padding="16px"
        background="rgba(217, 217, 217, 1)"
        borderRadius="10px"
        maxHeight={350}
        overflowY="auto"
      >
        {sortedTransactions.length > 0 ? (
          sortedTransactions.map((txn) => <TransactionRow key={txn.hash} txn={txn} />)
        ) : (
          <Text color="black" textAlign="center" width="100%">
            {t('No transaction recently')}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default WalletTransactions
