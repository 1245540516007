import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
// import getExternalLinkProps from '../../util/getExternalLinkProps'
// import Grid from '../../components/Box/Grid'
import Box from '../../components/Box/Box'
import getThemeValue from '../../util/getThemeValue'
import Text from '../../components/Text/Text'
import Heading from '../../components/Heading/Heading'
// import { Button } from '../../components/Button'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle, Dots, Dot } from '../Modal'
import WalletCard, { MoreWalletCard } from './WalletCard'
import config, { walletLocalStorageKey } from './config'
import { Config, Login } from './types'
import { Flex } from '../../components/Box'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const WalletWrapper = styled(Box)`
  padding: 24px 24px 12px;
`

const StyledModalContainer = styled(ModalContainer)`
  max-width: 420px;
  width: 100%;
`

const getPriority = (priority: Config['priority']) => (typeof priority === 'function' ? priority() : priority)

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => getPriority(a.priority) - getPriority(b.priority))

  const preferredWalletName = localStorage?.getItem(walletLocalStorageKey)

  if (!preferredWalletName) {
    return sortedConfig
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName)

  if (!preferredWallet) {
    return sortedConfig
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ]
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 3, t }) => {
  const [showMore, setShowMore] = useState(false)
  const theme = useTheme()
  const sortedConfig = getPreferredConfig(config)
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow = window.ethereum?.isTrust
    ? sortedConfig.filter((wallet) => wallet.title !== 'WalletConnect')
    : sortedConfig
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount)

  return (
    <StyledModalContainer minWidth={['240px', '320px', , '400px']}>
      <ModalHeader background={getThemeValue('colors.gradients.bubblegum')(theme)}>
        <ModalTitle>
          <Heading scale="lg">{t('Connect to Your Wallet')}</Heading>
        </ModalTitle>

        <Flex justifyContent="flex-end">
          <ModalCloseButton onDismiss={onDismiss} />
        </Flex>
      </ModalHeader>
      <ModalBody>
        <WalletWrapper maxHeight="453px" overflowY="auto">
          {displayListConfig.map((wallet) => (
            <Box key={wallet.title}>
              <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
            </Box>
          ))}
          {/* {!showMore && <MoreWalletCard t={t} onClick={() => setShowMore(true)} />} */}
        </WalletWrapper>
        <Box p="24px">
          <Text textAlign="center" color="black" as="p" fontSize="20px">
            {t('Please confirm in your wallet')}
          </Text>
          {/* <Button as="a" href="" width="100%" {...getExternalLinkProps()}>
            {t('Learn How to Connect')}
          </Button> */}
        </Box>
      </ModalBody>
    </StyledModalContainer>
  )
}

export default ConnectModal
