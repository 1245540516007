import React, { useState } from 'react'
import styled from 'styled-components'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Flex, UserMenuProps, useModal, MetamaskIcon } from 'packages/uikit'
import { UserMenuItemProps } from 'packages/uikit/src/widgets/Menu/components/UserMenu/types'
import WalletModal, { WalletView } from './WalletModal'

const UserMenuItem = styled.button<UserMenuItemProps>`
  align-items: center;
  border: 0;
  background: transparent;
  color: ${({ theme, disabled }) => theme.colors[disabled ? 'black' : 'black']};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  outline: 0;
  width: 100%;
  padding: 10px 0;

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  background-color: rgba(235, 190, 73, 1);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);

  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  border: 2px solid black;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);

    background: transparent;
    width: 100%;
    height: 25px;
  }

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled.div`
  color: black;
  font-size: 20px;

  display: block;
  margin-left: 8px;
  margin-right: 4px;
`

const Menu = styled.div`
  background-color: #fff5fa;
  border-radius: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  visibility: visible;
  z-index: 1001;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xxs} {
    max-width: 320px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: 420px;
  }

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const UserMenuWrapper: React.FC<UserMenuProps> = ({ account, text, ...props }) => {
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 5)}` : null

  const { error } = useWeb3React()
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu onClick={onClickWalletMenu}>
        <Flex width={27} height={27} borderRadius="50%" background="black" justifyContent="center" alignItems="center">
          <MetamaskIcon width="18px" />
        </Flex>
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
      </StyledUserMenu>
    </Flex>
  )
}

export default UserMenuWrapper
