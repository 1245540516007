import React from 'react'
import { Box, UserMenuItem, TelosIcon, ChainLogo } from 'packages/uikit'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import Wrapper from './Wrapper'

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`

interface Chain {
  url: string
  label: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
}

const chains: Chain[] = [{ url: '', label: 'Plume', Icon: ChainLogo }]

const NetworkSelect: React.FC = () => {
  const { t } = useTranslation()
  const origin = typeof window !== 'undefined' ? window.location.origin : ''
  const selectedOne = chains.find((c) => c.url.toLowerCase() === origin.toLowerCase())

  return (
    <>
      {/* <div style={{ padding: '10px' }}>
      <span style={{ color: 'black', fontWeight: '600' }}>{t('Switch Network')}</span>
      {chains.map((chain) => (
        <UserMenuItem
          key={chain.url}
          style={{ justifyContent: 'flex-start' }}
          onClick={() => {
            if (window) {
              window.location.replace(chain.url)
            }
          }}
        >
          {chain.Icon && <chain.Icon />}
          <span
            style={{
              paddingLeft: '12px',
              fontWeight: selectedOne?.url === chain.url.toLowerCase() ? 'bold' : 'normal',
              color: 'black',
            }}
          >
            {chain.label}
          </span>
        </UserMenuItem>
      ))}
    </div> */}
    </>
  )
}

export const NetworkSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const origin = typeof window !== 'undefined' ? window.location.origin : ''

  return (
    <StyledBox height="100%">
      <Wrapper mr="8px" placement="bottom" variant="default" text={t(chains[0]?.label)}>
        {() => <NetworkSelect />}
      </Wrapper>
    </StyledBox>
  )
}
